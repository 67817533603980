<template>
  <div  style="background-color:#F5F5F5">
    <div class="iteminfo">
        <div v-for="(item,index) in tabArr" :key="index" :title="item.title">
            <div class="itemtitle">{{item.title}}</div>
            <div>
                <van-grid square clickable :column-num="4" v-if="item.type!==1">
                    <van-grid-item class="itembody" @click="addTag(t)" v-for="(t,i) in item.child" :icon="t.icon" :text="t.text" :key="i" />
                </van-grid>
                <div v-else style="padding:10px">
                    <van-grid square clickable :column-num="4" >
                        <van-grid-item @click="addTag(t)" v-for="(t,i) in item.child" :icon="t.icon" :text="t.text" :key="i" />
                    </van-grid>
                    <van-field v-model="t.text" v-for="(t,i) in textArr" @blur="checkTag(t)" maxlength="10" :key="i" placeholder="请输入您的业务(1-10个字符)" />
                    <van-button icon="plus" type="primary" @click="addText" >添加业务</van-button>
                </div>
            </div>
        </div>
    </div>
    <div class="btns">
        <van-button block style="width:90%;margin:0px auto;" type="primary" @click="submit">下一步</van-button>
    </div>
  </div>
</template>

<script>
import {  Tab, Tabs, Grid, GridItem,Icon,Button,Field } from 'vant';
import {SaveProviderTag,GetAllBusinessTag} from '../../api/provider'
import {providerInfo} from '../../store/provider'
export default {
    components:{
        [Field.name]:Field,
        [Button.name]:Button,
        [Icon.name]:Icon,
        [Grid.name]:Grid,
        [GridItem.name]:GridItem,
        [Tab.name]:Tab,
        [Tabs.name]:Tabs
    },
    data(){
        return {
            active:'',
            inputTagId:'',
            textArr:[],
            tabArr:[{title:'标签1',child:[
                {text:'子标签1',icon:'checked'},
                {text:'子标签',icon:'add-o'},
                {text:'子标签',icon:'add-o'},
                {text:'子标签',icon:'add-o'},
                {text:'子标签',icon:'add-o'}]},
                {title:'标签2',child:[{text:'子标签',icon:'add-o'},]}],
            checkData:[]
        };
    },
    methods:{
        checkTag(item){
            item.type = 0;
            if(item.text){
                var a = this.tabArr.findIndex(a=>a.type == 1);
                this.tabArr[a].child.push(item);
                item.type = 1;
                this.checkData.push(item);
            }
            this.textArr = [];
        },
        addText(){
            this.textArr = [{text:'',pid:this.inputTagId,type:1, icon:'checked'}];
        },
        addTag(item){
            if(item.icon == 'checked'){
                item.icon = 'add-o';
                var i = this.checkData.findIndex(a=>a.id == item.id);
                this.checkData.splice(i,1);
            }else{
                item.icon = 'checked';
                this.checkData.push(item);
                console.log(this.checkData)
            }
        },
        submit(){
            if(this.checkData.length==0){
                this.$toast.fail("请选择");
                return;
            }
            var u = providerInfo.get();
            var items = [];
            this.checkData.forEach(e=>{
                items.push({
                    "id": e.id,
                    "parentId": e.pid,
                    "tagName": e.text,
                    "inputValue": e.text
                    });
            });
            var body ={
                "providerId": u.id,
                "items": items
            }
            SaveProviderTag(body).then(res=>{
                if(res.status){
                    this.$router.replace("/mindex?active=2&isload=1");
                }
            });
        }
    },
    created(){
        this.tabArr = [];
        GetAllBusinessTag().then(res=>{
            res.content.forEach(e => {   
                if(e.parentId===0){
                    if(e.controlType ==1){
                        this.inputTagId = e.id;
                    }
                    this.tabArr.push({title:e.tagName,id:e.id,pid:e.parentId,type:e.controlType,child:[]})
                }else{
                    var index = this.tabArr.findIndex(a=>a.id == e.parentId);
                    this.tabArr[index].child.push({text:e.tagName,pid:e.parentId,id:e.id,icon:'add-o'})
                }
            });
            console.log(this.tabArr);
        });
    }
}
</script>

<style scoped>
.btns{
    text-align: center;
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0px;
    height: 100px;
}

.iteminfo >>> .van-grid-item__text {
    /* color: #B7B7B7; */
    font-size: 12px;
    line-height: 1.5;
    word-break: break-all;
}
.iteminfo >>> .van-grid-item__content{
    margin: 3px;
}

.iteminfo >>> .van-icon::before {
    width: 36px;
    height: 36px;
    display: inline-block;

}
.iteminfo >>> .van-grid-item__icon {
    font-size: 28px;
    width: 36px;
    height: 36px;
}
.iteminfo >>> .van-icon-add-o::before {
    background:url('../../assets/image/abande.png');
    background-size: cover;
    /* content: '\F000'; */
    content:'';
    width: 36px;
    height: 36px;
}
.iteminfo >>> .van-icon-checked::before {
    /* content: '\F02F'; */
    content: '';
    background:url('../../assets/image/insist.png');
    background-size: cover;
}
.iteminfo >>> .van-icon::before {
    width: 36px;
    height: 36px;
    display: inline-block;
}
.iteminfo >>> .van-icon-plus::before {
    content: '\F0A2';
    width: 20px;
    height: 20px;
}

.iteminfo >>> .van-button--primary {
    color: #fff;
    background-color: #703BC1;
    border: 1px solid #4600AF;
}
.btns >>> .van-button--primary {
    color: #fff;
    background-color: #703BC1;
    border: 1px solid #4600AF;
}
.iteminfo{
    width: 93%;
    margin: 0 auto;
}
.iteminfo .itemtitle{
    color: #333333;
    font-size: 16px;
    padding-top: 14px;
    padding-bottom: 14px;
    font-weight: bold;
}

.iteminfo .itembody{
    font-size: 14px;
    /* font-weight: Medium;
    color: #B7B7B7; */
}
</style>